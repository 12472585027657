import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
  content: Content[]
}

interface Content {
  key: string
  value: string | number | null
}

const SummaryItem: React.FC<Props> = ({ content }) => {
  const { t } = useTranslation()

  return (
    <Paper sx={{ padding: 2, wordBreak: 'break-all', backgroundColor: 'grey.200' }}>
      {content.map((item, key) => (
        <Grid container direction='row' justifyContent='space-between' alignItems='center' key={key}>
          <Grid item xs>
            <Typography data-testid='summaryItemKey'>{t(item.key)}:</Typography>
          </Grid>
          <Grid item xs>
            <Typography data-testid='summaryItemValue'>{item.value ? item.value : ''}</Typography>
          </Grid>
        </Grid>
      ))}
    </Paper>
  )
}

export default SummaryItem
