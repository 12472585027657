import React from 'react'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { chainNullableK, fromNullable, getOrElse } from 'fp-ts/lib/Option'
import { constant, pipe } from 'fp-ts/lib/function'

import { UUID } from '@appointment-planner/api/types'
import { useService } from '@appointment-planner/service-provider'
import { useConfig } from '@appointment-planner/config-provider'
import { ActionTypes } from '@appointment-planner/state/applicationReducer'
import { StepContentProps } from '@appointment-planner/views/VerticalPlanner'
import Step2 from '@appointment-planner/components/vertical/Step2'
import { ShopAvailability } from '@appointment-planner/availability/models'

export type Step2ContainerProps = Pick<StepContentProps, 'activities' | 'appointmentTimes' | 'availability' | 'dispatch'>

const Step2Container: React.FC<Step2ContainerProps> = ({ activities, appointmentTimes, availability, dispatch }) => {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext<{ ActivityUUIDs: UUID[] }>()
  const [activitiesField] = useField<UUID[]>('ActivityUUIDs')
  const [dateField] = useField('Date')
  const [timeField] = useField('Time')
  const services = useService()
  const { enqueueSnackbar } = useSnackbar()
  const { ShopID } = useConfig()

  const isShopOpen = React.useMemo(() => {
    return Boolean(availability && Object.values(availability.days).some(day => day.availability !== ShopAvailability.Closed))
  }, [availability])

  const appointmentTimesOptions = React.useMemo(() => {
    return pipe(
      fromNullable(appointmentTimes),
      chainNullableK(appointmentTimes => appointmentTimes[dateField.value]),
      getOrElse(constant<string[]>([]))
    )
  }, [dateField.value, appointmentTimes])

  const activitiesWithCheckedProperty = React.useMemo(() => {
    return (activities || []).map(item => ({
      ...item,
      checked: activitiesField.value.includes(item.UUID) as boolean
    }))
  }, [activities, activitiesField.value])

  const onClickActivity = (uuid: UUID) => {
    const isActive = Boolean(activitiesField.value.includes(uuid))
    if (isActive) {
      setFieldValue(
        'ActivityUUIDs',
        activitiesField.value.filter((activity: string) => activity !== uuid)
      )
    } else {
      setFieldValue('ActivityUUIDs', [...activitiesField.value, uuid])
    }
  }

  React.useEffect(() => {
    if (!appointmentTimesOptions.includes(timeField.value)) {
      timeField.onChange('Time')('')
    }
  }, [timeField.value, appointmentTimesOptions])

  const isMountedRef = React.useRef(false)

  React.useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  }, [])

  React.useEffect(() => {
    services.activities.get(ShopID).then(result => {
      if (!result) enqueueSnackbar(t('Planner.Errors.FailedToLoadActivities'), { variant: 'error' })

      isMountedRef.current && dispatch({ type: ActionTypes.SetActivities, payload: result ? result.Activities : result })
    })

    services.appointmentTimes.get(ShopID).then(result => {
      if (!result) enqueueSnackbar(t('Planner.Errors.FailedToLoadAppointmentTimes'), { variant: 'error' })

      isMountedRef.current && dispatch({ type: ActionTypes.SetAppointmentTimes, payload: result })
    })
  }, [ShopID])

  React.useEffect(() => {
    services.availability.get(ShopID, activitiesField.value).then(result => {
      if (!result) enqueueSnackbar(t('Planner.Errors.FailedToLoadAvailability'), { variant: 'error' })

      isMountedRef.current && dispatch({ type: ActionTypes.SetAvailability, payload: result })
    })
  }, [ShopID, activitiesField.value])

  return (
    <Step2
      activities={activitiesWithCheckedProperty}
      appointmentTimes={appointmentTimesOptions}
      availability={availability}
      isShopOpen={isShopOpen}
      onClick={onClickActivity}
    />
  )
}

export default Step2Container
