import * as yup from 'yup'
import { Availability } from '@appointment-planner/state/applicationReducer'
import { ShopAvailability } from '@appointment-planner/availability/models'
import { UUID } from '@appointment-planner/api/types'

const createValidationSchema = (availability: Availability | null) => {
  return [
    {
      Registration: yup.string().max(12).required(),
      Odometer: yup.number().min(1).max(9999999).required()
    },
    {
      ActivityUUIDs: yup
        .array()
        .of(
          yup
            .mixed<UUID>()
            .test({
              test: value => UUID.is(value)
            })
            .required()
        )
        .required()
        .min(1),
      Date: yup
        .string()
        .required()
        .test('is-valid-date', 'date is not valid', value => {
          if (!availability || !value) return false

          const predicates = [
            (value: string) => Boolean(value && availability.days[value]),
            (value: string) => availability.days[value].availability !== ShopAvailability.Closed,
            (value: string) => availability.days[value].availability !== ShopAvailability.Low
          ]

          return predicates.every(predicate => predicate(value))
        }),
      Time: yup.string().required()
    },
    {
      FirstName: yup.string().required(),
      Name: yup.string().required(),
      EmailAddress: yup.string().email().required(),
      PhoneNumber: yup.string().required()
    }
  ] as const
}

export default createValidationSchema
