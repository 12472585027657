import React from 'react'
import { Grid, Typography } from '@mui/material'
import SummaryItem from '@appointment-planner/components/SummaryItem'
import { useTranslation } from 'react-i18next'

interface Props {
  activityRemark: string
  address: string
  city: string
  emailAddress: string
  firstName: string
  name: string
  odometer: string
  phoneNumber: string
  postalCode: string
  proposalDate: string
  proposalTime: string
  registration: string
  remark: string
  selectedActivityCodes: string
  vehicleBrand: string
  vehicleModel: string
  vehicleMOTExpiryDate: string
}

const Step4: React.FC<Props> = ({
  activityRemark,
  address,
  city,
  emailAddress,
  firstName,
  name,
  odometer,
  phoneNumber,
  postalCode,
  proposalDate,
  proposalTime,
  registration,
  remark,
  selectedActivityCodes,
  vehicleBrand,
  vehicleModel,
  vehicleMOTExpiryDate
}) => {
  const vehicleSummary = [
    { key: 'Planner.Fields.Licenseplate.Label', value: registration },
    { key: 'Planner.Fields.Odometer.Label', value: odometer },
    { key: 'Planner.Fields.Brand.Label', value: vehicleBrand },
    { key: 'Planner.Fields.Model.Label', value: vehicleModel },
    { key: 'Planner.MOTExpiryDate', value: vehicleMOTExpiryDate }
  ]

  const activitySummary = [
    { key: 'Planner.Fields.ProposalDate.Label', value: proposalDate },
    { key: 'Planner.Fields.ProposalTime.Label', value: proposalTime },
    { key: 'Planner.Fields.Activities.Label', value: selectedActivityCodes },
    { key: 'Planner.Fields.ActivityRemark.Label', value: activityRemark }
  ]

  const personalSummary = [
    { key: 'Planner.Fields.FirstName.Label', value: firstName },
    { key: 'Planner.Fields.Name.Label', value: name },
    { key: 'Planner.Fields.Address.Label', value: address },
    { key: 'Planner.Fields.PostalCode.Label', value: postalCode },
    { key: 'Planner.Fields.City.Label', value: city },
    { key: 'Planner.Fields.PhoneNumber.Label', value: phoneNumber },
    { key: 'Planner.Fields.EmailAddress.Label', value: emailAddress }
  ]
  const remarkSumary = [{ key: 'Planner.Fields.Remark.Label', value: remark }]
  const { t } = useTranslation()

  return (
    <Grid container flexGrow={1} spacing={2}>
      <Grid item xs={12}>
        <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
          {t('Planner.SummaryInformation.Description')}
        </Typography>
        <SummaryItem content={vehicleSummary} />
      </Grid>
      <Grid item xs={12}>
        <SummaryItem content={activitySummary} />
      </Grid>
      <Grid item xs={12}>
        <SummaryItem content={personalSummary} />
      </Grid>
      <Grid item xs={12}>
        <SummaryItem content={remarkSumary} />
      </Grid>
    </Grid>
  )
}

export default Step4
